
    <template>
      <section class="content element-doc">
        <h2>Steps 步骤条</h2>
<p>引导用户按照流程完成任务的分步导航条，可根据实际应用场景设定步骤，步骤不得少于 2 步。</p>
<h3>基础用法</h3>
<p>简单的步骤条。</p>
<demo-block>
        <div><p>设置<code>active</code>属性，接受一个<code>Number</code>，表明步骤的 index，从 0 开始。需要定宽的步骤条时，设置<code>space</code>属性即可，它接受<code>Number</code>，单位为<code>px</code>，如果不设置，则为自适应。设置<code>finish-status</code>属性可以改变已经完成的步骤的状态。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;active&quot; finish-status=&quot;success&quot;&gt;
    &lt;el-step title=&quot;步骤 1&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 2&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;

  &lt;el-button style=&quot;margin-top: 12px;&quot; @click=&quot;next&quot;&gt;下一步&lt;/el-button&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const active = ref(0)
      const next = () =&gt; {
        if (active.value++ &gt; 2) {
          active.value = 0
        }
      }
      return { active, next }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>含状态步骤条</h3>
<p>每一步骤显示出该步骤的状态。</p>
<demo-block>
        <div><p>也可以使用<code>title</code>具名分发，可以用<code>slot</code>的方式来取代属性的设置，在本文档最后的列表中有所有的 slot name 可供参考。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :space=&quot;200&quot; :active=&quot;1&quot; finish-status=&quot;success&quot;&gt;
    &lt;el-step title=&quot;已完成&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;进行中&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>设置当前步骤的状态</h3>
<p>自定义当前步骤的状态</p>
<demo-block>
        <div><p>设置当前步骤的状态，可选值 wait / process / finish / error / success， 默认为 process</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;2&quot; :process-status=&quot;processStatus&quot; finish-status=&quot;success&quot;&gt;
    &lt;el-step title=&quot;step1&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;step2&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;step3&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;

  &lt;el-button style=&quot;margin-top: 12px;&quot; @click=&quot;chageStatus&quot;&gt;点我试试&lt;/el-button&gt;
&lt;/template&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      let processStatus = ref('error')
      function chageStatus() {
        processStatus.value =
          processStatus.value == 'process' ? 'error' : 'process'
      }
      return { processStatus, chageStatus }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>有描述的步骤条</h3>
<p>每个步骤有其对应的步骤状态描述。</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;1&quot;&gt;
    &lt;el-step
      title=&quot;步骤 1&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
    &lt;el-step
      title=&quot;步骤 2&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot; description=&quot;这段就没那么长了&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>居中的步骤条</h3>
<p>标题和描述都将居中。</p>
<demo-block>
        
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;2&quot; align-center&gt;
    &lt;el-step
      title=&quot;步骤1&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
    &lt;el-step
      title=&quot;步骤2&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
    &lt;el-step
      title=&quot;步骤3&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
    &lt;el-step
      title=&quot;步骤4&quot;
      description=&quot;这是一段很长很长很长的描述性文字&quot;
    &gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>带图标的步骤条</h3>
<p>步骤条内可以启用各种自定义的图标。</p>
<demo-block>
        <div><p>通过<code>icon</code>属性来设置图标，图标的类型可以参考 Icon 组件的文档，除此以外，还能通过具名<code>slot</code>来使用自定义的图标。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;1&quot;&gt;
    &lt;el-step title=&quot;步骤 1&quot; icon=&quot;el-icon-edit&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 2&quot; icon=&quot;el-icon-upload&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot; icon=&quot;el-icon-picture&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>竖式步骤条</h3>
<p>竖直方向的步骤条。</p>
<demo-block>
        <div><p>只需要在<code>el-steps</code>元素中设置<code>direction</code>属性为<code>vertical</code>即可。</p>
</div>
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div style=&quot;height: 300px;&quot;&gt;
    &lt;el-steps direction=&quot;vertical&quot; :active=&quot;1&quot;&gt;
      &lt;el-step title=&quot;步骤 1&quot;&gt;&lt;/el-step&gt;
      &lt;el-step title=&quot;步骤 2&quot;&gt;&lt;/el-step&gt;
      &lt;el-step
        title=&quot;步骤 3&quot;
        description=&quot;这是一段很长很长很长的描述性文字&quot;
      &gt;&lt;/el-step&gt;
    &lt;/el-steps&gt;
  &lt;/div&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>简洁风格的步骤条</h3>
<p>设置 <code>simple</code> 可应用简洁风格，该条件下 <code>align-center</code> / <code>description</code> / <code>direction</code> / <code>space</code> 都将失效。</p>
<demo-block>
        
        <template #source><element-demo7 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-steps :active=&quot;1&quot; simple&gt;
    &lt;el-step title=&quot;步骤 1&quot; icon=&quot;el-icon-edit&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 2&quot; icon=&quot;el-icon-upload&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot; icon=&quot;el-icon-picture&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;

  &lt;el-steps :active=&quot;1&quot; finish-status=&quot;success&quot; simple style=&quot;margin-top: 20px&quot;&gt;
    &lt;el-step title=&quot;步骤 1&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 2&quot;&gt;&lt;/el-step&gt;
    &lt;el-step title=&quot;步骤 3&quot;&gt;&lt;/el-step&gt;
  &lt;/el-steps&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>Steps Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>space</td>
<td>每个 step 的间距，不填写将自适应间距。支持百分比。</td>
<td>number / string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>direction</td>
<td>显示方向</td>
<td>string</td>
<td>vertical/horizontal</td>
<td>horizontal</td>
</tr>
<tr>
<td>active</td>
<td>设置当前激活步骤</td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
<tr>
<td>process-status</td>
<td>设置当前步骤的状态</td>
<td>string</td>
<td>wait / process / finish / error / success</td>
<td>process</td>
</tr>
<tr>
<td>finish-status</td>
<td>设置结束步骤的状态</td>
<td>string</td>
<td>wait / process / finish / error / success</td>
<td>finish</td>
</tr>
<tr>
<td>align-center</td>
<td>进行居中对齐</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>simple</td>
<td>是否应用简洁风格</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Step Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>title</td>
<td>标题</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>description</td>
<td>描述性文字</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>icon</td>
<td>图标</td>
<td>传入 icon 的 class 全名来自定义 icon，也支持 slot 方式写入</td>
<td>string</td>
<td>—</td>
</tr>
<tr>
<td>status</td>
<td>设置当前步骤的状态，不设置则根据 steps 确定状态</td>
<td>wait / process / finish / error / success</td>
<td>-</td>
<td></td>
</tr>
</tbody>
</table>
<h3>Step Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>icon</td>
<td>自定义图标</td>
</tr>
<tr>
<td>title</td>
<td>自定义标题</td>
</tr>
<tr>
<td>description</td>
<td>自定义描述性文字</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_steps, {
      active: _ctx.active,
      "finish-status": "success"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "步骤 1" }),
        _createVNode(_component_el_step, { title: "步骤 2" }),
        _createVNode(_component_el_step, { title: "步骤 3" })
      ]),
      _: 1
    }, 8, ["active"]),
    _createVNode(_component_el_button, {
      style: {"margin-top":"12px"},
      onClick: _ctx.next
    }, {
      default: _withCtx(() => [
        _createTextVNode("下一步")
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const active = ref(0)
      const next = () => {
        if (active.value++ > 2) {
          active.value = 0
        }
      }
      return { active, next }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock(_component_el_steps, {
    space: 200,
    active: 1,
    "finish-status": "success"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_step, { title: "已完成" }),
      _createVNode(_component_el_step, { title: "进行中" }),
      _createVNode(_component_el_step, { title: "步骤 3" })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_steps, {
      active: 2,
      "process-status": _ctx.processStatus,
      "finish-status": "success"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "step1" }),
        _createVNode(_component_el_step, { title: "step2" }),
        _createVNode(_component_el_step, { title: "step3" })
      ]),
      _: 1
    }, 8, ["process-status"]),
    _createVNode(_component_el_button, {
      style: {"margin-top":"12px"},
      onClick: _ctx.chageStatus
    }, {
      default: _withCtx(() => [
        _createTextVNode("点我试试")
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      let processStatus = ref('error')
      function chageStatus() {
        processStatus.value =
          processStatus.value == 'process' ? 'error' : 'process'
      }
      return { processStatus, chageStatus }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock(_component_el_steps, { active: 1 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_step, {
        title: "步骤 1",
        description: "这是一段很长很长很长的描述性文字"
      }),
      _createVNode(_component_el_step, {
        title: "步骤 2",
        description: "这是一段很长很长很长的描述性文字"
      }),
      _createVNode(_component_el_step, {
        title: "步骤 3",
        description: "这段就没那么长了"
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock(_component_el_steps, {
    active: 2,
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_step, {
        title: "步骤1",
        description: "这是一段很长很长很长的描述性文字"
      }),
      _createVNode(_component_el_step, {
        title: "步骤2",
        description: "这是一段很长很长很长的描述性文字"
      }),
      _createVNode(_component_el_step, {
        title: "步骤3",
        description: "这是一段很长很长很长的描述性文字"
      }),
      _createVNode(_component_el_step, {
        title: "步骤4",
        description: "这是一段很长很长很长的描述性文字"
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock(_component_el_steps, { active: 1 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_step, {
        title: "步骤 1",
        icon: "el-icon-edit"
      }),
      _createVNode(_component_el_step, {
        title: "步骤 2",
        icon: "el-icon-upload"
      }),
      _createVNode(_component_el_step, {
        title: "步骤 3",
        icon: "el-icon-picture"
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock("div", { style: {"height":"300px"} }, [
    _createVNode(_component_el_steps, {
      direction: "vertical",
      active: 1
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "步骤 1" }),
        _createVNode(_component_el_step, { title: "步骤 2" }),
        _createVNode(_component_el_step, {
          title: "步骤 3",
          description: "这是一段很长很长很长的描述性文字"
        })
      ]),
      _: 1
    })
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo7": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_steps, {
      active: 1,
      simple: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, {
          title: "步骤 1",
          icon: "el-icon-edit"
        }),
        _createVNode(_component_el_step, {
          title: "步骤 2",
          icon: "el-icon-upload"
        }),
        _createVNode(_component_el_step, {
          title: "步骤 3",
          icon: "el-icon-picture"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_steps, {
      active: 1,
      "finish-status": "success",
      simple: "",
      style: {"margin-top":"20px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "步骤 1" }),
        _createVNode(_component_el_step, { title: "步骤 2" }),
        _createVNode(_component_el_step, { title: "步骤 3" })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  